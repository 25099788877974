import React, { useEffect } from "react";
import { useAdmin } from "../context/adminContext";
import { useFirestore } from "../context/firestoreContext";
import { BsPersonFill } from "react-icons/bs";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export default function ProfileCard() {
  const { userData, updateProfile, storage } = useFirestore();
  const { state, dispatch } = useAdmin();
  const { imgFile, imgSrc, profileName, about, loading } = state;

  const handleFile = (event) => {
    event.preventDefault();

    dispatch({ type: "field", field: "imgFile", value: event.target.files[0] });
  };

  const handleCancel = () => {
    dispatch({ type: "field", field: "imgSrc", value: userData.page.imgSrc });
    dispatch({ type: "field", field: "imgFile", value: null });
  };

  const handleRemove = async () => {
    dispatch({ type: "field", field: "imgSrc", value: null });

    await updateProfile(userData.userId, {
      page: { ...userData.page, imgSrc: null },
    });
  };

  const handleUpload = () => {
    dispatch({ type: "update" });

    if (imgFile) {
      const imgFileStorageRef = ref(storage, `users/${userData.username}`);
      const imgFileUploadTask = uploadBytesResumable(
        imgFileStorageRef,
        imgFile,
      );

      imgFileUploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress
        },
        (error) => {
          console.log(error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(
              imgFileUploadTask.snapshot.ref,
            );
            await updateProfile(userData.userId, {
              page: { ...userData.page, imgSrc: downloadURL },
            });
            dispatch({ type: "success" });
          } catch (error) {
            dispatch({ type: "error", error: error.message });
          }
        },
      );
    }
  };

  useEffect(() => {
    if (imgFile) {
      const reader = new FileReader();

      reader.onload = function (e) {
        dispatch({ type: "field", field: "imgSrc", value: e.target.result });
      };

      reader.readAsDataURL(imgFile);
    }
  }, [imgFile]);

  return (
    <div className="rounded-2xl bg-black/40 p-6 backdrop-blur-sm space-y-6">
      <h2 className="text-xl font-bold text-white">Profile</h2>
      
      {/* Profile Image Section */}
      <div className="flex flex-col items-center space-y-4 lg:flex-row lg:space-y-0 lg:space-x-6">
        {imgSrc ? (
          <img
            src={imgSrc}
            alt=""
            className="h-32 w-32 rounded-full ring-2 ring-gray-700 object-cover"
          />
        ) : (
          <div className="h-32 w-32 rounded-full ring-2 ring-gray-700 bg-black/30 flex items-center justify-center">
            <BsPersonFill className="h-16 w-16 text-gray-400" />
          </div>
        )}
        
        <div className="flex flex-1 items-center justify-center space-x-2">
          {imgFile ? (
            <>
              <button
                onClick={handleCancel}
                className="w-full rounded-lg bg-red-500/20 px-4 py-2 text-red-400 hover:bg-red-500/30 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleUpload}
                className="w-full rounded-lg bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600 transition-colors"
              >
                {loading ? "Uploading..." : "Upload"}
              </button>
            </>
          ) : (
            <>
              <button
                disabled={!imgSrc}
                onClick={handleRemove}
                className="w-full rounded-lg bg-red-500/20 px-4 py-2 text-red-400 hover:bg-red-500/30 transition-colors disabled:opacity-50"
              >
                Remove
              </button>
              <label className="w-full rounded-lg bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600 transition-colors cursor-pointer text-center">
                Change
                <input
                  className="hidden"
                  aria-label="profile pic"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleFile}
                />
              </label>
            </>
          )}
        </div>
      </div>

      {/* Profile Info */}
      <div className="space-y-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">
            Profile Title
          </label>
          <input
            type="text"
            value={profileName}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "profileName",
                value: e.target.value,
              })
            }
            placeholder="@yourname"
            className="w-full rounded-lg bg-black/50 p-4 text-white placeholder-gray-500 outline-none ring-1 ring-gray-700 transition-all focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">
            Profile Description
          </label>
          <textarea
            value={about}
            onChange={(e) =>
              dispatch({
                type: "field",
                field: "about",
                value: e.target.value,
              })
            }
            placeholder="Bio"
            className="w-full rounded-lg bg-black/50 p-4 text-white placeholder-gray-500 outline-none ring-1 ring-gray-700 transition-all focus:ring-2 focus:ring-indigo-500 min-h-[100px] resize-none"
          />
        </div>
      </div>
    </div>
  );
}
