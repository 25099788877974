import { useReducer } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import { useAuth } from "../context/authContext";
import {
  initialState,
  resetPasswordReducer,
} from "../reducers/resetPasswordReducer";

export default function ForgotPassword() {
  const { resetPassword } = useAuth();
  const [state, dispatch] = useReducer(resetPasswordReducer, initialState);
  const { emailAddress, message, error, loading } = state;
  const isInvalid = emailAddress === "";

  const handleReset = (event) => {
    event.preventDefault();

    try {
      dispatch({ type: "update" });
      resetPassword(emailAddress).then(() => {
        dispatch({ type: "success" });
      });
    } catch (error) {
      dispatch({ type: "error", error: error.message });
    }
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-black">
      {/* Background Elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/4 top-1/4 h-64 w-64 rounded-full bg-purple-300/30 blur-3xl"></div>
        <div className="absolute right-1/4 top-1/3 h-64 w-64 rounded-full bg-blue-300/30 blur-3xl"></div>
        <div className="absolute bottom-1/4 left-1/3 h-64 w-64 rounded-full bg-pink-300/30 blur-3xl"></div>
      </div>

      <div className="relative z-10 flex min-h-screen w-full flex-col items-center justify-center p-4">
        <form
          onSubmit={handleReset}
          className="w-full max-w-md space-y-6 rounded-2xl bg-white/80 p-8 shadow-xl backdrop-blur-sm dark:bg-gray-900/80"
        >
          {error && (
            <p className="text-center font-medium text-red-500">{error}</p>
          )}
          {message && (
            <p className="text-center font-medium text-green-500">{message}</p>
          )}
          <h1 className="text-center text-3xl font-bold text-gray-900 dark:text-white">
            Reset Password
          </h1>
          <div className="space-y-4">
            <input
              type="email"
              value={emailAddress}
              onChange={({ target }) =>
                dispatch({
                  type: "field",
                  field: "emailAddress",
                  value: target.value,
                })
              }
              className="w-full rounded-lg bg-white/50 p-4 text-gray-900 outline-none ring-1 ring-gray-200 transition-all focus:ring-2 focus:ring-indigo-500 dark:bg-black/50 dark:text-white dark:ring-gray-800"
              placeholder="Email"
            />
            <button
              disabled={isInvalid || loading}
              type="submit"
              className={`w-full rounded-lg bg-indigo-500 px-6 py-4 font-medium text-white transition-all hover:bg-indigo-600 disabled:bg-gray-400 dark:disabled:bg-gray-800 ${
                loading && "animate-pulse"
              }`}
            >
              {loading ? "Sending..." : "Reset Password"}
            </button>
          </div>
          <div className="flex justify-center space-x-6 text-center">
            <Link
              to={ROUTES.LOGIN}
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Back to Login
            </Link>
            <Link
              to={ROUTES.REGISTER}
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Create Account
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
