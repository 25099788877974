import { useState, useRef } from "react";
import { BsPersonFill, BsPlayFill, BsPauseFill } from "react-icons/bs";

export default function Page({
  username,
  imgSrc,
  profileName,
  about,
  links,
  appearance,
  socials,
  music,
  styleClasses,
  preview = false
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showContent, setShowContent] = useState(preview);
  const audioRef = useRef(null);

  const handleInitialClick = async () => {
    setShowContent(true);
    if (audioRef.current) {
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.log("Play prevented:", error);
        setIsPlaying(false);
      }
    }
  };

  const togglePlay = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch(error => {
            console.log("Play prevented:", error);
            setIsPlaying(false);
          });
      }
    }
  };

  const backgroundStyle = {
    backgroundImage: appearance?.backgroundImage ? `url(${appearance.backgroundImage})` : 'none',
    backgroundColor: appearance?.backgroundColor || '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  };

  // Initial click screen
  if (!preview && !showContent) {
    return (
      <div
        className="min-h-screen w-full flex items-center justify-center cursor-pointer"
        style={backgroundStyle}
        onClick={handleInitialClick}
      >
        <div className="absolute inset-0 bg-black/40" />
        <audio ref={audioRef} src={music} loop />
        <div className="relative z-10">
          <h1 className="text-white text-2xl font-bold text-center animate-pulse">
            Click Anywhere
          </h1>
        </div>
      </div>
    );
  }

  // Main content
  return (
    <div
      className={`min-h-screen flex items-center justify-center p-4 ${styleClasses}`}
      style={backgroundStyle}
    >
      {appearance?.backgroundImage && (
        <div className="absolute inset-0 bg-black/40" />
      )}

      <div className="relative z-10 w-full max-w-md space-y-4">
        <div className="rounded-2xl bg-black/40 backdrop-blur-xl border border-white/10 p-8 shadow-2xl">
          {/* Profile Section */}
          <div className="flex flex-col items-center space-y-4">
            {imgSrc ? (
              <img
                src={imgSrc}
                alt=""
                className="h-24 w-24 rounded-full object-cover ring-2 ring-white/20"
              />
            ) : (
              <div className="h-24 w-24 rounded-full bg-white/5 flex items-center justify-center ring-2 ring-white/20">
                <BsPersonFill className="h-12 w-12 text-white/50" />
              </div>
            )}
            <h1 className="text-2xl font-bold text-white text-center">
              {profileName}
            </h1>
            {about && (
              <p className="text-gray-300 text-center text-sm">{about}</p>
            )}
          </div>

          {/* Links Section */}
          <div className="mt-8 space-y-3">
            {links
              ?.filter((link) => link.active !== false && link.title && link.link)
              .map((link, index) => (
                <a
                  key={index}
                  href={link.link}
                  target="_blank"
                  rel="noreferrer"
                  className="block w-full p-3 text-white text-center 
                           hover:text-gray-300 transition-all 
                           bg-white/5 hover:bg-white/10 rounded-lg"
                >
                  {link.title}
                </a>
              ))}
          </div>

          {/* Social Icons */}
          <div className="mt-8 flex justify-center space-x-4">
            {/* Your social icons here */}
          </div>
        </div>

        {/* Music Player */}
        {music && (
          <div className="rounded-2xl bg-black/40 backdrop-blur-xl border border-white/10 p-4 shadow-2xl flex justify-center">
            <button
              onClick={togglePlay}
              className="bg-white/10 hover:bg-white/20 backdrop-blur-xl 
                       rounded-full p-4 border border-white/10 
                       transition-all hover:scale-110"
            >
              {isPlaying ? (
                <BsPauseFill size={28} className="text-white" />
              ) : (
                <BsPlayFill size={28} className="text-white" />
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
