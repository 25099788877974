import { useEffect, useReducer } from "react";
import * as ROUTES from "../constants/routes";
import { useAuth } from "../context/authContext";
import { useHeader } from "../context/headerContext";
import { initialState, loginReducer } from "../reducers/loginReducer";
import { Link } from "react-router-dom";

export default function Login() {
  const { login } = useAuth();
  const { setCustomHeader } = useHeader();
  const [state, dispatch] = useReducer(loginReducer, initialState);
  const { email, password, error, loading } = state;
  const invalid = email === "" || password === "";

  const handleLogIn = async (event) => {
    event.preventDefault();
    dispatch({ type: "login" });
    try {
      await login(email, password);
    } catch (error) {
      const message = error.message
        .split(/(?<=\/)(.*?)(?=\))/gm)[1]
        .replace(/-/g, " ");
      dispatch({ type: "error", error: message });
    }
  };

  useEffect(() => {
    setCustomHeader(null);
    return () => dispatch({ type: "field", field: "loading", value: false });
  }, [setCustomHeader]);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-black">
      {/* Background Elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/4 top-1/4 h-64 w-64 rounded-full bg-purple-300/30 blur-3xl"></div>
        <div className="absolute right-1/4 top-1/3 h-64 w-64 rounded-full bg-blue-300/30 blur-3xl"></div>
        <div className="absolute bottom-1/4 left-1/3 h-64 w-64 rounded-full bg-pink-300/30 blur-3xl"></div>
      </div>

      <div className="relative z-10 flex min-h-screen w-full flex-col items-center justify-center p-4">
        <form className="w-full max-w-md space-y-6 rounded-2xl bg-white/80 p-8 shadow-xl backdrop-blur-sm dark:bg-gray-900/80">
          {error && (
            <p className="text-center font-medium text-red-500">{error}</p>
          )}
          <h1 className="text-center text-3xl font-bold text-gray-900 dark:text-white">
            Welcome back
          </h1>
          <div className="space-y-4">
            <input
              value={email}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "email",
                  value: e.target.value,
                })
              }
              type="email"
              className="w-full rounded-lg bg-white/50 p-4 text-gray-900 outline-none ring-1 ring-gray-200 transition-all focus:ring-2 focus:ring-indigo-500 dark:bg-black/50 dark:text-white dark:ring-gray-800"
              placeholder="Email"
            />
            <input
              value={password}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "password",
                  value: e.target.value,
                })
              }
              type="password"
              className="w-full rounded-lg bg-white/50 p-4 text-gray-900 outline-none ring-1 ring-gray-200 transition-all focus:ring-2 focus:ring-indigo-500 dark:bg-black/50 dark:text-white dark:ring-gray-800"
              placeholder="Password"
            />
            <button
              disabled={invalid || loading}
              onClick={handleLogIn}
              className={`w-full rounded-lg bg-indigo-500 px-6 py-4 font-medium text-white transition-all hover:bg-indigo-600 disabled:bg-gray-400 dark:disabled:bg-gray-800 ${
                loading && "animate-pulse"
              }`}
            >
              {loading ? "Logging in..." : "Log In"}
            </button>
          </div>
          <div className="space-y-2 text-center">
            <Link
              to={ROUTES.REGISTER}
              className="block text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Don&apos;t have an account? Sign up
            </Link>
            <Link
              to={ROUTES.RESET_PASSWORD}
              className="text-sm text-gray-500 hover:text-gray-700 dark:text-gray-500 dark:hover:text-gray-300"
            >
              Forgot your password?
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
