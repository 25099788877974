import React, { useEffect } from "react";
import { HexColorPicker } from "react-colorful";
import { useAdmin } from "../context/adminContext";
import { useFirestore } from "../context/firestoreContext";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { MdAddPhotoAlternate } from "react-icons/md";
import { toast } from "react-hot-toast";

export default function Appearance() {
  const { userData, updateProfile, storage } = useFirestore();
  const { state, dispatch } = useAdmin();
  const { bgImgFile, appearance, loading } = state;

  const backgroundPresetColors = [
    "#000000",
    "#212529",
    "#ff5b5b",
    "#fca311",
    "#e5e5e5",
  ];
  const linkPresetColors = [
    "#000000",
    "#212529",
    "#ff5b5b",
    "#fca311",
    "#e5e5e5",
  ];

  const fontColors = ["#fff", "#e5e5e5", "#212529", "#000"];

  const handleFile = (event) => {
    event.preventDefault();

    dispatch({
      type: "field",
      field: "bgImgFile",
      value: event.target.files[0],
    });
  };

  const handleCancel = () => {
    dispatch({
      type: "field",
      field: "appearance",
      value: {
        ...appearance,
        background: userData.page.appearance.background,
      },
    });
    dispatch({ type: "field", field: "bgImgFile", value: null });
  };

  const handleRemove = async () => {
    dispatch({
      type: "field",
      field: "appearance",
      value: { ...appearance, background: null },
    });

    await updateProfile(userData.userId, {
      page: {
        ...userData.page,
        appearance: { ...userData.page.appearance, background: null },
      },
    });
  };

  const handleUpload = () => {
    dispatch({ type: "update" });

    if (bgImgFile) {
      const storageRef = ref(storage, `background/${userData.username}`);
      const uploadTask = uploadBytesResumable(storageRef, bgImgFile);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // progress
        },
        (error) => {
          console.log(error.message);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateProfile(userData.userId, {
              page: {
                ...userData.page,
                appearance: {
                  ...userData.page.appearance,
                  background: downloadURL,
                },
              },
            });
            dispatch({ type: "success" });
          } catch (error) {
            dispatch({ type: "error", error: error.message });
          }
        },
      );
    }
  };

  useEffect(() => {
    if (bgImgFile) {
      const reader = new FileReader();

      reader.onload = function (e) {
        dispatch({
          type: "field",
          field: "appearance",
          value: { ...appearance, background: e.target.result },
        });
      };

      reader.readAsDataURL(bgImgFile);
    }
  }, [bgImgFile]);

  const handleBackgroundImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `backgrounds/${Date.now()}-${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error("Error uploading background:", error);
          toast.error('Failed to upload background');
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          dispatch({
            type: "field",
            field: "appearance",
            value: { ...appearance, backgroundImage: downloadURL },
          });
          toast.success('Background uploaded successfully!');
        }
      );
    } catch (error) {
      console.error("Error handling background upload:", error);
      toast.error('Failed to handle background upload');
    }
  };

  const handleMusicUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type and size
    if (!file.type.startsWith('audio/')) {
      toast.error('Please upload an audio file');
      return;
    }

    // 10MB limit
    if (file.size > 10 * 1024 * 1024) {
      toast.error('Audio file must be less than 10MB');
      return;
    }

    try {
      const filename = `${Date.now()}-${file.name}`;
      const storageRef = ref(storage, `music/${userData.username}/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      toast.loading('Uploading music...', { id: 'musicUpload' });

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error("Error uploading music:", error);
          toast.error('Failed to upload music', { id: 'musicUpload' });
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            // Update both local state and profile
            dispatch({
              type: "field",
              field: "music",
              value: downloadURL,
            });

            // Update the profile with the new music URL
            await updateProfile(userData.userId, {
              page: {
                ...userData.page,
                music: downloadURL,
              },
            });

            toast.success('Music uploaded successfully!', { id: 'musicUpload' });
          } catch (error) {
            console.error("Error saving music URL:", error);
            toast.error('Failed to save music information', { id: 'musicUpload' });
          }
        }
      );
    } catch (error) {
      console.error("Error handling music upload:", error);
      toast.error('Failed to handle music upload');
    }
  };

  return (
    <div className="space-y-6">

      {/* Background Image Upload */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">
          Background Image
        </label>
        <div className="space-y-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleBackgroundImage}
            className="w-full rounded-lg bg-black/50 p-2 text-white file:mr-4 
                     file:py-2 file:px-4 file:rounded-full file:border-0 
                     file:text-sm file:font-semibold file:bg-indigo-500 
                     file:text-white hover:file:bg-indigo-600"
          />
          {appearance?.backgroundImage && (
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-300">Background uploaded</span>
              <button
                onClick={() =>
                  dispatch({
                    type: "field",
                    field: "appearance",
                    value: { ...appearance, backgroundImage: null },
                  })
                }
                className="text-red-400 text-sm hover:text-red-300"
              >
                Remove background
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Music Upload */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">
          Profile Music
        </label>
        <div className="space-y-2">
          <input
            type="file"
            accept="audio/*"
            onChange={handleMusicUpload}
            className="w-full rounded-lg bg-black/50 p-2 text-white file:mr-4 
                     file:py-2 file:px-4 file:rounded-full file:border-0 
                     file:text-sm file:font-semibold file:bg-indigo-500 
                     file:text-white hover:file:bg-indigo-600"
          />
          {state.music && (
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-300">Music uploaded</span>
              <button
                onClick={() =>
                  dispatch({
                    type: "field",
                    field: "music",
                    value: null,
                  })
                }
                className="text-red-400 text-sm hover:text-red-300"
              >
                Remove music
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Background Color */}
      <div className="space-y-2">
        <label className="block text-sm font-medium text-white">
          Background Color
        </label>
        <div className="flex flex-wrap gap-2">
          {[
            '#000000', '#FF0000', '#00FF00', '#0000FF', 
            '#FFFF00', '#FF00FF', '#00FFFF', '#FFFFFF'
          ].map((color) => (
            <button
              key={color}
              onClick={() =>
                dispatch({
                  type: "field",
                  field: "appearance",
                  value: { ...appearance, backgroundColor: color },
                })
              }
              className={`h-8 w-8 rounded-full border-2 ${
                appearance?.backgroundColor === color
                  ? 'border-white'
                  : 'border-transparent'
              }`}
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      </div>

      {/* Link Style */}
      <div className="space-y-4">
        <h3 className="text-lg font-medium text-white">Link Style</h3>
        <div className="grid gap-4">
          {/* Link Color */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Link Color
            </label>
            <div className="flex flex-wrap gap-2">
              {linkPresetColors.map((color) => (
                <button
                  key={color}
                  onClick={() =>
                    dispatch({
                      type: "field",
                      field: "appearance",
                      value: { ...appearance, linkColor: color },
                    })
                  }
                  className="h-8 w-8 rounded-full border-2 border-white/20"
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>

          {/* Link Text Color */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-white">
              Link Text Color
            </label>
            <div className="flex flex-wrap gap-2">
              {fontColors.map((color) => (
                <button
                  key={color}
                  onClick={() =>
                    dispatch({
                      type: "field",
                      field: "appearance",
                      value: { ...appearance, linkFontColor: color },
                    })
                  }
                  className="h-8 w-8 rounded-full border-2 border-white/20"
                  style={{ backgroundColor: color }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
