import React from "react";
import { useAdmin } from "../context/adminContext";
import { HiOutlineLink } from "react-icons/hi";

export default function Editor() {
  const { state, dispatch } = useAdmin();
  const { links, socials } = state;

  const addLink = () => {
    dispatch({
      type: "field",
      field: "links",
      value: [...(links || []), { title: "", link: "", active: true }],
    });
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    dispatch({
      type: "field",
      field: "links",
      value: newLinks,
    });
  };

  return (
    <div className="space-y-6">
      {/* Links Section */}
      <div className="space-y-4">
        <h2 className="text-xl font-bold text-white">Links</h2>
        
        {links?.map((link, index) => (
          <div key={index} className="space-y-2">
            <div className="flex items-center space-x-2">
              <input
                type="text"
                placeholder="Link Title"
                value={link.title}
                onChange={(e) => {
                  const newLinks = [...links];
                  newLinks[index].title = e.target.value;
                  dispatch({
                    type: "field",
                    field: "links",
                    value: newLinks,
                  });
                }}
                className="w-full rounded-lg bg-black/50 p-4 text-white placeholder-gray-500 outline-none ring-1 ring-gray-700 transition-all focus:ring-2 focus:ring-indigo-500"
              />
              <button
                onClick={() => removeLink(index)}
                className="rounded-lg bg-red-500/20 p-4 text-red-500 hover:bg-red-500/30 transition-colors"
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <input
              type="text"
              placeholder="URL"
              value={link.link}
              onChange={(e) => {
                const newLinks = [...links];
                newLinks[index].link = e.target.value;
                dispatch({
                  type: "field",
                  field: "links",
                  value: newLinks,
                });
              }}
              className="w-full rounded-lg bg-black/50 p-4 text-white placeholder-gray-500 outline-none ring-1 ring-gray-700 transition-all focus:ring-2 focus:ring-indigo-500"
            />
          </div>
        ))}

        <button
          onClick={addLink}
          className="w-full rounded-lg bg-indigo-500/20 p-4 text-indigo-500 hover:bg-indigo-500/30 transition-colors flex items-center justify-center space-x-2"
        >
          <HiOutlineLink size={20} />
          <span>Add Link</span>
        </button>
      </div>

      {/* Social Icons Section */}
      <div className="space-y-4">
        <h2 className="text-xl font-bold text-white">Social Icons</h2>
        
        <div className="space-y-4">
          {Object.entries(socials || {}).map(([platform, value]) => (
            <input
              key={platform}
              type="text"
              placeholder={`${platform.charAt(0).toUpperCase() + platform.slice(1)} handle`}
              value={value || ""}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "socials",
                  value: { ...socials, [platform]: e.target.value },
                })
              }
              className="w-full rounded-lg bg-black/50 p-4 text-white placeholder-gray-500 outline-none ring-1 ring-gray-700 transition-all focus:ring-2 focus:ring-indigo-500"
            />
          ))}
        </div>
      </div>
    </div>
  );
}
