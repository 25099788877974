import { useEffect, useReducer } from "react";
import * as ROUTES from "../constants/routes";
import { useHeader } from "../context/headerContext";
import { useAuth } from "../context/authContext";
import { useFirestore } from "../context/firestoreContext";
import { initialState, registerReducer } from "../reducers/registerReducer";
import { Link, useNavigate } from "react-router-dom";

export default function Register() {
  const { setCustomHeader } = useHeader();
  const { signup } = useAuth();
  const { createUser, getUserDoc } = useFirestore();

  const navigate = useNavigate();

  const [state, dispatch] = useReducer(registerReducer, initialState);
  const { username, email, password, error, loading } = state;

  const invalid = username === "" || email === "" || password === "";

  const handleSignUp = async (event) => {
    event.preventDefault();

    dispatch({ type: "register" });

    const usernameTaken = await getUserDoc(username);

    if (!usernameTaken) {
      try {
        const { user } = await signup(email, password);
        await createUser(user.uid, username, email);
        dispatch({ type: "success" });
        navigate(ROUTES.ADMIN);
      } catch (error) {
        const message = error.message
          .split(/(?<=\/)(.*?)(?=\))/gm)[1]
          .replace(/-/g, " ");
        dispatch({
          type: "error",
          error: message,
        });
      }
    } else {
      dispatch({ type: "error", error: "username already taken" });
    }
  };

  useEffect(() => {
    setCustomHeader(null);
  }, [setCustomHeader]);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-black">
      {/* Background Elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/4 top-1/4 h-64 w-64 rounded-full bg-purple-300/30 blur-3xl"></div>
        <div className="absolute right-1/4 top-1/3 h-64 w-64 rounded-full bg-blue-300/30 blur-3xl"></div>
        <div className="absolute bottom-1/4 left-1/3 h-64 w-64 rounded-full bg-pink-300/30 blur-3xl"></div>
      </div>

      <div className="relative z-10 flex min-h-screen w-full flex-col items-center justify-center p-4">
        <form className="w-full max-w-md space-y-6 rounded-2xl bg-white/80 p-8 shadow-xl backdrop-blur-sm dark:bg-gray-900/80">
          {error && (
            <p className="text-center font-medium text-red-500">{error}</p>
          )}
          <h1 className="text-center text-3xl font-bold text-gray-900 dark:text-white">
            Create your account
          </h1>
          <div className="space-y-4">
            <div className="flex items-center overflow-hidden rounded-lg bg-white/50 ring-1 ring-gray-200 dark:bg-black/50 dark:ring-gray-800">
              <span className="px-4 text-gray-500 dark:text-gray-400">
                w33d.lol/
              </span>
              <input
                value={username}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    field: "username",
                    value: e.target.value,
                  })
                }
                className="w-full bg-transparent p-4 text-gray-900 outline-none dark:text-white"
                placeholder="username"
              />
            </div>
            <input
              value={email}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "email",
                  value: e.target.value,
                })
              }
              type="email"
              className="w-full rounded-lg bg-white/50 p-4 text-gray-900 outline-none ring-1 ring-gray-200 transition-all focus:ring-2 focus:ring-indigo-500 dark:bg-black/50 dark:text-white dark:ring-gray-800"
              placeholder="Email"
            />
            <input
              value={password}
              onChange={(e) =>
                dispatch({
                  type: "field",
                  field: "password",
                  value: e.target.value,
                })
              }
              type="password"
              className="w-full rounded-lg bg-white/50 p-4 text-gray-900 outline-none ring-1 ring-gray-200 transition-all focus:ring-2 focus:ring-indigo-500 dark:bg-black/50 dark:text-white dark:ring-gray-800"
              placeholder="Password"
            />
            <button
              disabled={invalid || loading}
              onClick={handleSignUp}
              className={`w-full rounded-lg bg-indigo-500 px-6 py-4 font-medium text-white transition-all hover:bg-indigo-600 disabled:bg-gray-400 dark:disabled:bg-gray-800 ${
                loading && "animate-pulse"
              }`}
            >
              {loading ? "Creating account..." : "Create Account"}
            </button>
          </div>
          <div className="text-center">
            <Link
              to={ROUTES.LOGIN}
              className="text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
            >
              Already have an account? Log in
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}
