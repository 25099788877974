import React from "react";
import { useAdmin } from "../context/adminContext";
import Page from "./page";
import PropTypes from "prop-types";
import { useFirestore } from "../context/firestoreContext";

export default function Preview({ preview = true }) {
  const { userData } = useFirestore();
  const { state } = useAdmin();
  const { imgSrc, profileName, about, links, appearance, socials, music } = state;

  return (
    <div className="h-full w-full">
      {!userData ? (
        <div className="shine h-full w-full rounded-xl"></div>
      ) : (
        <Page
          styleClasses="w-full h-full rounded-xl overflow-hidden"
          username={userData.username}
          imgSrc={imgSrc}
          profileName={profileName}
          about={about}
          links={links}
          appearance={appearance}
          socials={socials}
          music={music}
          preview={preview}
        />
      )}
    </div>
  );
}

Preview.propTypes = {
  preview: PropTypes.bool.isRequired,
};
