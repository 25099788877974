import React from "react";
import { LogOut, LogIn, User, ChevronRight } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import * as ROUTES from "../constants/routes";

export default function Home() {
  // Mock authentication state
  const isAuthenticated = false;
  const navigate = useNavigate();

  const handleLogout = () => {
    // Add your logout logic here
    navigate(ROUTES.Home);
  };

  const Header = () => (
    <header className="fixed top-0 z-50 w-full bg-white/80 px-4 py-4 backdrop-blur-md dark:bg-black/80">
      <div className="mx-auto flex max-w-6xl items-center justify-between">
        <img src="/logo.png" alt="Logo" className="h-8" />
        <div className="flex items-center space-x-4">
          {isAuthenticated ? (
            <>
              <button
                onClick={handleLogout}
                className="rounded-full bg-black/10 px-6 py-2 font-medium text-black backdrop-blur-sm hover:bg-black/20 dark:bg-white/10 dark:text-white dark:hover:bg-white/20"
              >
                <LogOut className="mr-2 inline-block h-4 w-4" />
                Log Out
              </button>
              <Link
                to={ROUTES.ADMIN}
                className="rounded-full bg-indigo-500 px-6 py-2 font-medium text-white hover:bg-indigo-600"
              >
                Dashboard
              </Link>
            </>
          ) : (
            <>
              <Link
                to={ROUTES.LOGIN}
                className="rounded-full bg-black/10 px-6 py-2 font-medium text-black backdrop-blur-sm hover:bg-black/20 dark:bg-white/10 dark:text-white dark:hover:bg-white/20"
              >
                <LogIn className="mr-2 inline-block h-4 w-4" />
                Login
              </Link>
              <Link
                to={ROUTES.REGISTER}
                className="rounded-full bg-indigo-500 px-6 py-2 font-medium text-white hover:bg-indigo-600"
              >
                <User className="mr-2 inline-block h-4 w-4" />
                Get Started
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
  const Footer = () => (
    <footer className="w-full bg-white py-8 dark:bg-gray-900">
      <div className="mx-auto max-w-6xl px-4">
        <div className="flex flex-col items-center space-y-4">
          <div className="flex items-center space-x-4">
            <div className="text-center text-gray-600 dark:text-gray-400">
              © 2024 W33d. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-white to-gray-100 dark:from-gray-900 dark:to-black">
      <Header />

      {/* Hero Section */}
      <section className="relative min-h-screen px-4 pt-20">
        {/* Background Elements */}
        <div className="absolute inset-0 z-0">
          <div className="absolute left-1/4 top-1/4 h-64 w-64 rounded-full bg-purple-300/30 blur-3xl"></div>
          <div className="absolute right-1/4 top-1/3 h-64 w-64 rounded-full bg-blue-300/30 blur-3xl"></div>
          <div className="absolute bottom-1/4 left-1/3 h-64 w-64 rounded-full bg-pink-300/30 blur-3xl"></div>
        </div>

        <div className="relative z-10 mx-auto max-w-6xl pt-20">
          <div className="flex flex-col items-center text-center">
            <h1 className="mb-6 text-6xl font-bold tracking-tight text-black dark:text-white md:text-7xl">
              Your Digital Identity,
              <br />
              <span className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 bg-clip-text text-transparent">
                Simplified
              </span>
            </h1>

            <div className="mb-8 text-xl text-gray-600 dark:text-gray-300">
              One link to showcase your entire online presence
            </div>

            <div className="flex flex-col items-center space-y-4">
              <div className="flex items-center space-x-2 rounded-full bg-black/5 p-2 dark:bg-white/5">
                <span className="px-3 text-gray-600 dark:text-gray-400">
                  w33d.lol/
                </span>
                <Link
                  to={ROUTES.REGISTER}
                  className="rounded-full bg-black px-6 py-2 font-medium text-white hover:bg-gray-800 dark:bg-white dark:text-black dark:hover:bg-gray-200"
                >
                  Claim Your Link
                </Link>
              </div>
            </div>
          </div>

          {/* Preview Images */}
          <div className="mt-20">
            <div className="flex justify-center space-x-4">
              <div className="h-96 w-64 rounded-t-3xl bg-gray-200 object-cover shadow-2xl dark:bg-gray-800" />
              <div className="h-96 w-64 rounded-t-3xl bg-gray-200 object-cover shadow-2xl dark:bg-gray-800" />
              <div className="h-96 w-64 rounded-t-3xl bg-gray-200 object-cover shadow-2xl dark:bg-gray-800" />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="w-full bg-white/5 py-20 dark:bg-black/5">
        <div className="container mx-auto px-4">
          <h2 className="mb-16 text-center text-4xl font-bold text-black dark:text-white">
            Features that set us apart
          </h2>

          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {features.map((feature, index) => (
              <div
                key={index}
                className="rounded-2xl bg-white p-8 shadow-xl transition-all hover:shadow-2xl dark:bg-gray-800"
              >
                <div className="mb-4 text-4xl">{feature.icon}</div>
                <h3 className="mb-4 text-2xl font-bold text-black dark:text-white">
                  {feature.title}
                </h3>
                <p className="text-lg text-gray-600 dark:text-gray-400">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Additional Features */}
      <section className="w-full py-20">
        <div className="container mx-auto px-4">
          <div className="grid gap-8 md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h2 className="mb-6 text-4xl font-bold text-black dark:text-white">
                Customize Everything
              </h2>
              <p className="text-lg text-gray-600 dark:text-gray-400">
                Make your profile truly yours with our powerful customization
                tools. Change colors, fonts, layouts, and more with just a few
                clicks.
              </p>
            </div>
            <div className="rounded-2xl bg-gradient-to-r from-purple-500/10 to-pink-500/10 p-8">
              <div className="h-64 rounded-xl bg-white/10 backdrop-blur-sm"></div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="w-full bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-pink-500/10 py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="mb-8 text-4xl font-bold text-black dark:text-white">
            Ready to get started?
          </h2>
          <Link
            to={ROUTES.REGISTER}
            className="inline-flex items-center rounded-full bg-indigo-500 px-8 py-3 font-medium text-white transition-all hover:bg-indigo-600"
          >
            Create Your Profile
            <ChevronRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      </section>

      <Footer />
    </div>
  );
}

const features = [
  {
    icon: "🎨",
    title: "Custom Themes",
    description:
      "Create your unique style with our powerful theme editor. Choose from countless combinations of colors, fonts, and layouts.",
  },
  {
    icon: "⚡",
    title: "Lightning Fast",
    description:
      "Optimized for speed and performance. Your profile loads instantly, keeping your visitors engaged.",
  },
  {
    icon: "📱",
    title: "Mobile First",
    description:
      "Perfect display on all devices. Your profile looks great whether viewed on desktop, tablet, or phone.",
  },
  {
    icon: "🔒",
    title: "Secure",
    description:
      "Your data is safe with us. We use industry-standard encryption and security practices.",
  },
  {
    icon: "📊",
    title: "Analytics",
    description:
      "Track your profile's performance with detailed analytics and insights.",
  },
  {
    icon: "❤️",
    title: "Community",
    description:
      "Join a thriving community of people and connect with others who share your interests.",
  },
];
