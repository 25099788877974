import { useEffect, useState } from "react";
import * as ROUTES from "../constants/routes";
import { useAuth } from "../context/authContext";
import { useFirestore } from "../context/firestoreContext";
import { useHeader } from "../context/headerContext";
import { useAdmin } from "../context/adminContext";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Share from "../components/share";
import Editor from "../components/Editor";
import Appearance from "../components/appearance";
import Preview from "../components/Preview";
import ProfileCard from "../components/ProfileCard";
import {
  HiOutlineLink,
  HiOutlinePencil,
  HiOutlineUpload,
} from "react-icons/hi";
import { ImSpinner } from "react-icons/im";
import { BsBrush } from "react-icons/bs";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";

export default function Admin() {
  const { logOut } = useAuth();
  const { userData, updateProfile, storage } = useFirestore();
  const { setCustomHeader } = useHeader();
  const { state, dispatch } = useAdmin();
  const { 
    username, 
    profileName, 
    about, 
    links, 
    appearance, 
    socials, 
    loading,
    music 
  } = state;

  const [preview, setPreview] = useState(false);
  const [showDesign, setShowDesign] = useState(true);

  const handleMusicUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch({ type: "update" });
      const musicRef = ref(storage, `users/${userData.username}/music/${file.name}`);
      const uploadTask = uploadBytesResumable(musicRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          dispatch({ type: "error", error: error.message });
          toast.error('Failed to upload music');
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            await updateProfile(userData.userId, {
              page: {
                ...userData.page,
                music: downloadURL,
              },
            });
            dispatch({ type: "field", field: "music", value: downloadURL });
            toast.success('Music uploaded successfully!');
          } catch (error) {
            dispatch({ type: "error", error: error.message });
            toast.error('Failed to save music');
          }
        }
      );
    }
  };

  const handleUpdate = async (event) => {
    if (event) event.preventDefault();

    dispatch({ type: "update" });

    try {
      const updateData = {
        page: {
          profileName: profileName,
          about: about,
          links: links,
          appearance: {
            ...appearance,
            backgroundImage: appearance?.backgroundImage || null,
            backgroundColor: appearance?.backgroundColor || '#000000'
          },
          socials: socials,
          music: music || null,
        },
      };

      console.log('Saving data:', updateData);

      await updateProfile(userData.userId, updateData);
      dispatch({ type: "success" });
      toast.success('Changes saved successfully!');
    } catch (error) {
      console.error('Update error:', error);
      dispatch({ type: "error", error: error.message });
      toast.error('Failed to save changes');
    }
  };

  useEffect(() => {
    const customHeader = (
      <Link
        to={`${ROUTES.PROFILE}`}
        className="bg-primary-accent font-nunito hover:bg-secondary-accent rounded-3xl px-4 py-2 font-bold text-white"
      >
        My Account
      </Link>
    );

    setCustomHeader(customHeader);
  }, [logOut, setCustomHeader]);

  return (
    <div className="relative min-h-screen bg-gradient-to-b from-gray-900 to-black">
      <div className="absolute inset-0 z-0">
        <div className="absolute left-1/4 top-1/4 h-64 w-64 rounded-full bg-purple-300/30 blur-3xl"></div>
        <div className="absolute right-1/4 top-1/3 h-64 w-64 rounded-full bg-blue-300/30 blur-3xl"></div>
        <div className="absolute bottom-1/4 left-1/3 h-64 w-64 rounded-full bg-pink-300/30 blur-3xl"></div>
      </div>

      <div className="relative z-10 min-h-screen">
        <div className="container mx-auto p-4">
          {/* Header */}
          <div className="mb-8 flex items-center justify-between">
            <h1 className="text-3xl font-bold text-white">
              Edit Profile
            </h1>
            <Link
              to={`/${username}`}
              className="rounded-lg bg-indigo-500 px-4 py-2 text-white hover:bg-indigo-600 transition-colors"
            >
              View Profile
            </Link>
          </div>

          {/* Main Grid */}
          <div className="grid gap-8 lg:grid-cols-2">
            {/* Editor Section */}
            <div className="space-y-6">
              {showDesign ? (
                <>
                  {/* Profile & Design Section */}
                  <ProfileCard />
                  
                  {/* Appearance Settings */}
                  <div className="rounded-2xl bg-black/40 p-6 backdrop-blur-sm">
                    <h2 className="mb-4 text-xl font-bold text-white">
                      Appearance
                    </h2>
                    <Appearance />
                  </div>

                  {/* Music Upload */}
                  <div className="rounded-2xl bg-black/40 p-6 backdrop-blur-sm">
                    <h2 className="mb-4 text-xl font-bold text-white">
                      Profile Music
                    </h2>
                    <input
                      type="file"
                      accept="audio/*"
                      onChange={handleMusicUpload}
                      className="w-full rounded-lg border border-gray-700 bg-black/50 p-2 text-white
                               file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 
                               file:text-sm file:font-semibold file:bg-indigo-500 
                               file:text-white hover:file:bg-indigo-600"
                    />
                    {music && (
                      <div className="mt-2 flex items-center justify-between">
                        <span className="text-sm text-gray-300">Music uploaded</span>
                        <button
                          onClick={() => {
                            dispatch({ type: "field", field: "music", value: null });
                            updateProfile(userData.userId, {
                              page: {
                                ...userData.page,
                                music: null,
                              },
                            });
                          }}
                          className="text-red-400 text-sm hover:text-red-300"
                        >
                          Remove music
                        </button>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                // Links Section
                <div className="rounded-2xl bg-black/40 p-6 backdrop-blur-sm">
                  <Editor />
                </div>
              )}
            </div>

            {/* Preview Section */}
            <div className="relative">
              <div className="sticky top-4 h-[800px] overflow-y-auto rounded-2xl bg-black/40 p-4 backdrop-blur-sm">
                <div className="flex items-center justify-center min-h-full">
                  <div className="w-full max-w-md">
                    <Preview preview={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Navigation */}
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-black/40 backdrop-blur-md">
          <div className="container mx-auto flex items-center justify-between p-4">
            <div className="flex space-x-4">
              <button
                onClick={() => setShowDesign(!showDesign)}
                className="flex items-center space-x-2 rounded-lg px-4 py-2 text-white hover:bg-white/10"
              >
                {showDesign ? (
                  <>
                    <HiOutlineLink size={20} />
                    <span>Links</span>
                  </>
                ) : (
                  <>
                    <BsBrush size={20} />
                    <span>Design</span>
                  </>
                )}
              </button>
            </div>

            <button
              onClick={handleUpdate}
              disabled={loading}
              className="flex items-center space-x-2 rounded-lg bg-indigo-500 px-6 py-2 text-white 
                       hover:bg-indigo-600 disabled:bg-gray-800 disabled:cursor-not-allowed 
                       transition-colors"
            >
              {loading ? (
                <>
                  <ImSpinner className="animate-spin" size={20} />
                  <span>Saving...</span>
                </>
              ) : (
                <>
                  <HiOutlineUpload size={20} />
                  <span>Save Changes</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
